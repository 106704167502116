import { useRef, useState } from "react";

export default function HomePage() {
  const canvasRef = useRef();
  const flier = { width: 788, height: 940 }

  const [refreshing, setRefreshing] = useState(false);

  const [quote, setQuote] = useState("")
  const [finalFlier, setFinalFlier] = useState('')
  const templates = ['/flier/3.png', '/flier/2.png', '/flier/1.png']

  const wrapText = function (ctx, text, x, y, maxWidth, lineHeight) {
    // First, start by splitting all of our text into words, but splitting it into an array split by spaces
    let words = text.split(' ');
    let line = ''; // This will store the text of the current line
    let testLine = ''; // This will store the text when we add a word, to test if it's too long
    let lineArray = []; // This is an array of lines, which the function will return

    // Lets iterate over each word
    for (var n = 0; n < words.length; n++) {
      // Create a test line, and measure it..
      testLine += `${words[n]} `;
      let metrics = ctx.measureText(testLine);
      let testWidth = metrics.width;
      // If the width of this test line is more than the max width
      if (testWidth > maxWidth && n > 0) {
        // Then the line is finished, push the current line into "lineArray"
        lineArray.push([line, x, y]);
        // Increase the line height, so a new line is started
        y += lineHeight;
        // Update line and test line to use this word as the first word on the next line
        line = `${words[n]} `;
        testLine = `${words[n]} `;
      }
      else {
        // If the test line is still less than the max width, then add the word to the current line
        line += `${words[n]} `;
      }
      // If we never reach the full max width, then there is only one line.. so push it into the lineArray so we return something
      if (n === words.length - 1) {
        lineArray.push([line, x, y]);
      }
    }
    // Return the line array
    return lineArray;
  }

  const processBanner = async (index, textColor) => {
    setRefreshing(true);

    const img = new Image();
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    img.src = templates[index];

    img.onload = async () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);
      context.font = "30px Open Sans";
      context.fillStyle = textColor || "black";
      context.textAlign = "center";

      let wrappedText = wrapText(context, quote, flier.width / 2, 400, 560, 40);
      wrappedText.forEach(function (item) {
        context.fillText(item[0], item[1], item[2]);
      })

      setFinalFlier(canvas.toDataURL())
      setRefreshing(false);
    };
  };

  return (
    <main className="bg-gray-200 min-h-screen p-8 text-sm">
      <img src='/icon.png' className="mx-auto w-32 pb-8" alt="banner" />

      <div className="max-w-screen-md mx-auto border bg-white p-4">
        <canvas ref={canvasRef} className="hidden" />

        <div className="grid grid-cols-1 gap-4">
          <header className="text-center font-semibold pb-4">Enter your quote and click one of the download buttons</header>
          <textarea placeholder="Enter quote here" className="border-2 border-black p-4 h-32 w-full mb-4"
            onChange={v => setQuote(v.target.value)}></textarea>
          <button onClick={() => processBanner(0, "black")} className="bg-black py-4 px-8 w-full text-white">GENERATE TEMPLATE 1</button>
          <button onClick={() => processBanner(1, "white")} className="bg-black py-4 px-8 w-full text-white">GENERATE TEMPLATE 2</button>
          <button onClick={() => processBanner(2, "white")} className="bg-black py-4 px-8 w-full text-white">GENERATE TEMPLATE 3</button>
        </div>

        {(refreshing || finalFlier) &&
          <div className="flex h-screen w-screen top-0 right-0 items-end md:items-center justify-center fixed">
            <div className="h-screen bg-black/75 w-screen z-10 absolute" onClick={() => setFinalFlier('')} />
            <div className="bg-white w-lg max-w-sm p-8 z-20 grid grid-cols-1 gap-4 m-4">
              {refreshing ?
                <>
                  <img src="/icon.png" className="mx-auto h-20 animate-spin w-20" alt="loading" />
                  <div className="text-center text-sm">Processing... Please wait.</div>
                </>
                :
                <>
                  <h1 className="text-center text-base font-semibold">Banner Generated Successfully</h1>
                  <img src={finalFlier} className="w-full" alt="banner" />

                  <div className="flex space-x-4">
                    <button onClick={() => setFinalFlier('')} className="bg-white border-2 border-black p-2 w-2/5 text-black">CLOSE</button>
                    <a
                      href={finalFlier}
                      download="rock-ministry.png"
                      className="bg-black p-2 w-3/5 text-center text-white">DOWNLOAD</a>
                  </div>
                </>
              }
            </div>
          </div>
        }
      </div>

      <div className="text-center text-xs pt-8">
        Made with &hearts; by <a href="http://www.ogbeni.ng" className="font-semibold">OgbeniTech</a>
      </div>
    </main>
  );
}

export function LoadingIndicator({ label, required, children }) {
  return (
    <div className="flex h-screen w-screen top-0 right-0 items-center justify-center fixed">
      <div className="h-screen bg-black/75 w-screen z-10 absolute" onClick={() => { }} />
      <div className="bg-white w-sm p-8 z-20">
        <img src="/icon.png" className="mx-auto h-20 animate-spin w-20" alt="loading" />
        <div className="text-center text-sm pt-4">Processing... Please wait.</div>
      </div>
    </div>
  );
}
